import MainMenu from "./components/menus/MainMenu";
import HorizontalMenu from "./components/menus/HorizontalMenu";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState, lazy, Suspense } from "react";
import ScrollToTop from "./components/helpers/ScrollToTop";
import LoadingPage from "./pages/LoadingPage";
import ReactTooltip from "react-tooltip";
import MetamaskProvider from "./providers/MetamaskProvider";
import { Helmet, HelmetProvider } from "react-helmet-async";

const HomePage = lazy(() => import("./pages/HomePage"));
const NotFound = lazy(() => import("./pages/NotFound"));
const ArtistPage = lazy(() => import("./pages/ArtistPage"));
const ProjectPage = lazy(() => import("./pages/ProjectPage"));
const ProjectPortalPage = lazy(() => import("./pages/ProjectPortalPage"));
const ProjectsOverviewPage = lazy(() => import("./pages/ProjectsOverviewPage"));
const ArtistsOverviewPage = lazy(() => import("./pages/ArtistsOverviewPage"));
const TermsOfServicePage = lazy(() => import("./pages/TermsOfServicePage"));
const PrivacyPolicyPage = lazy(() => import("./pages/PrivacyPolicyPage"));
const AdminPage = lazy(() => import("./pages/AdminPage"));
const GalleryPage = lazy(() => import("./pages/GalleryPage"));
const ArtistPortalPage = lazy(() => import("./pages/ArtistPortalPage"));
const ArtFromChain = lazy(() => import("./pages/ArtFromChain"));


function App() {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const [hideOverlay, setHideOverlay] = useState(false);

  const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  const [theme, setTheme] = useState(localStorage.getItem('theme') || systemTheme);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  useEffect(() => {
    console.log(
      `
    ██████╗ ███████╗ ██████╗ 
    ╚════██╗██╔════╝██╔════╝ 
     █████╔╝███████╗███████╗ 
    ██╔═══╝ ╚════██║██╔═══██╗
    ███████╗███████║╚██████╔╝
    ╚══════╝╚══════╝ ╚═════╝
     `
    );
    console.log("Hope you enjoyed this little 256 ASCII art fellow nerd! 🙃");
    document.fonts.ready.then(function () {
      setFontsLoaded(true);
      setTimeout(function () {
        setHideOverlay(true);
      }, 600);
    });
  }, []);

  return (
    <HelmetProvider>
      <MetamaskProvider>
        <div className={`App theme-${theme}`}>
          <Helmet>
            <title>256ART</title>
            <meta name="title" content="256ART" />
            <meta
              name="description"
              content="The only fully in-chain and open generative art platform."
            />

            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://256art.com/" />
            <meta property="og:title" content="256ART" />
            <meta
              property="og:description"
              content="The only fully in-chain and open generative art platform."
            />
            <meta
              property="og:image"
              content="https://256art.com/assets/256ART/256art.png"
            />

            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://256art.com/" />
            <meta property="twitter:title" content="256ART" />
            <meta
              property="twitter:description"
              content="The only fully in-chain and open generative art platform."
            />
            <meta
              property="twitter:image"
              content="https://256art.com/assets/256ART/256art.png"
            />
          </Helmet>

          <div
            className={
              "app-overlay " +
              (fontsLoaded ? " " : "font-loading ") +
              (hideOverlay ? " hide " : "")
            }
          >
            <p>...</p>
          </div>
          <BrowserRouter>
            <ScrollToTop />
            <MainMenu toggleTheme={toggleTheme} theme={theme} />
            <HorizontalMenu toggleTheme={toggleTheme} theme={theme} />
            <Suspense fallback={<LoadingPage></LoadingPage>}>
              <Routes>
                <Route path="/artist">
                  <Route path=":identifier" element={<ArtistPage />} />
                  <Route path="" element={<NotFound />} />
                  <Route path="*" element={<NotFound />} />
                </Route>

                <Route path="/project">
                  <Route path=":id" element={<ProjectPage />} />
                  <Route path="*" element={<NotFound />} />
                </Route>

                <Route path="/portalproject">
                  <Route path=":id" element={<ProjectPortalPage />} />
                  <Route path="*" element={<NotFound />} />
                </Route>

                <Route path="/projects" element={<ProjectsOverviewPage />} />

                <Route path="/artists" element={<ArtistsOverviewPage />} />

                <Route path="/terms" element={<TermsOfServicePage />} />
                <Route path="/privacy" element={<PrivacyPolicyPage />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/gallery" element={<GalleryPage />} />
                <Route path="/live/:projectAddress/:tokenId" element={<ArtFromChain />} />
                <Route path="/live/:projectAddress/:tokenId/:chainId" element={<ArtFromChain />} />
                <Route path="/portal" element={<ArtistPortalPage />} />

                <Route index element={<HomePage />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
          <ReactTooltip class="tooltip" html={true} />
        </div>
      </MetamaskProvider>
    </HelmetProvider>
  );
}

export default App;
